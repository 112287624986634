<template>
  <div class="row text-start">
    <div class="col-md-1"></div>
    <br/><br/>
    <h3>Dodaj wielu użytkowników</h3>
    <hr>

    <div class="col-md-10">
      <template v-if="addUsersResult">
        <template v-if="addUsersResult.isSuccess">
          <div class="alert alert-success" role="alert">
            {{addUsersResult.message}}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{addUsersResult.message}}
          </div>
        </template>
      </template>
      <form @submit.prevent="submit()">
        <label class="form-check-label" for="insertCodes">
          Podaj emaile użytkowników (odzielone enterem)
        </label>
        <div class="form-group">
          <label for="codes"></label>
          <textarea class="form-control" id="usersEmails" v-model="users.emails" rows="3"></textarea>
        </div>

        <div class="form-group ">
          <label for="password" class="col-sm-2 col-form-label">Password:</label>
          <div class="col-sm-5">
            <input class="form-control" id="password" v-model="users.password">
          </div>
        </div>

        <div class="form-group ">
          <label for="retype-password" class="col-sm-2 col-form-label">Retype password:</label>
          <div class="col-sm-5">
            <input class="form-control" id="retype-password" v-model="users.retypePassword">
          </div>
        </div>
        <br/>
        <h4>Add user access:</h4>
        <br/>
        <input style="float: left" type="radio" id="one" value="End Date" v-model="accessType" />
        <label style="float: left" for="one">End Date</label>
        <br/>
        <input style="float: left" type="radio" id="two" value="Number of days" v-model="accessType" />
        <label style="float: left" for="two">Number of days</label>
        <br/>

        <div class="form-group row" v-if="accessType === 'End Date'">
          <label for="endDate" class="col-sm-2 col-form-label">End date:</label>
          <div class="col-sm-10">
            <input class="form-control" id="endDate" v-model="access.endDate" placeholder="Please, provide date in the following format: 2023-03-08">
          </div>
        </div>
        <div class="form-group row" v-if="accessType === 'Number of days'">
          <label for="daysNumber" class="col-sm-2 col-form-label">Number of access days:</label>
          <div class="col-sm-10">
            <input class="form-control" id="daysNumber" v-model="access.daysNumber" placeholder="Please, provide number of access days">
          </div>
        </div>
        <button type="submit" class="btn btn-primary">
          Dodaj użytkowników
        </button>
      </form>
    </div>
    <div class="col-md-1"></div>
  </div>
</template>

<script>

import UserService from '../../../services/userService';

export default {
  name: 'User',
  data() {
    return {
      users: {
        emails: null,
        password: null,
        retypePassword: null
      },
      addUsersResult: null,
      access: {
        daysNumber: null,
        endDate: null
      },
      accessType: null
    };
  },
  methods: {
    async submit() {
      await this.addManyUsers();
    },
    async addManyUsers() {
      this.addUsersResult = null;

      if (this.users.password !== this.users.retypePassword) {
        this.addUsersResult = {
          isSuccess: false,
          message: 'Password and retype password are not the same!'
        };
        return;
      }

      const userEmailsSplitted = this.users.emails ? this.users.emails.split(/\r?\n/) : null;
      const usersData = [];

      for (const email of userEmailsSplitted) {
        usersData.push({
          email: email,
          password: this.users.password,
          courseId: 1,
          daysNumber: (this.accessType === 'Number of days' && this.access.daysNumber) ? this.access.daysNumber : null,
          expiredAt: (this.accessType === 'End Date' && this.access.endDate) ? this.access.endDate : null
        });
      }

      this.addUsersResult = (await UserService.addManyUsers(usersData)).data;
    }
  }
};
</script>

<style scoped>
.list-table td, .list-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.list-table tr:nth-child(even){background-color: #f2f2f2;}

.list-table tr:hover {background-color: #ddd;}

.list-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
